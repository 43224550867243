import * as $ from "jquery";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";
import parser from "ua-parser-js";

import { IWidget, WidgetOptions, ObjectRecord } from "../global";

const JSONParse = (json: string): string => {
	try {
		return JSON.parse(json);
	} catch {
		return json;
	}
}

class CloudSignsWidget implements IWidget {
	private url = JSONParse(process.env.SIGNER_URL ?? "");
	private id = uuid() as string;
	private widget: JQuery | undefined;
	private wrapper: JQuery | undefined;
	private root = $("body") as JQuery;
	private page: string = new Date().toISOString();
	private form: JQuery;
	private loader: JQuery;

	constructor(formID: string) {
		this.form = $(`#${formID}`);
	}

	private handleSendMessage(message: ObjectRecord) {
		if(this.widget) {
			const childWindow: HTMLIFrameElement = this.widget?.get(0) as HTMLIFrameElement;

			childWindow?.contentWindow?.postMessage(message, "*");
		}
	}

	public destroy(): void {
		this.root.css({ overflow: "initial" });

		this.widget?.remove();
		this.wrapper?.remove();

		$(window).off("message");

		delete this.widget;
		delete this.wrapper;
	}

	private appendLoader(node: JQuery) {
		const loader = $(`
			<div id="loader-${this.id}" class="cs-loader">
				<hr/><hr/><hr/><hr/>
			</div>
		`);

		this.loader = loader;

		if(node) {
			node?.append(loader);
			node?.append(
				`
					<style>
						#loader-${this.id}.cs-loader{
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 40px;
							height: 40px;
						}

						#loader-${this.id}.cs-loader hr{
							border: 0;
							margin: 0;
							width: 40%;
							height: 40%;
							position: absolute;
							border-radius: 50%;
							animation: spin 2s ease infinite;
						}

						#loader-${this.id}.cs-loader :first-child{
							background: #5D4D70;
							animation-delay: -1.5s
						}

						#loader-${this.id}.cs-loader :nth-child(2){
							background: #9D9ABF;
							animation-delay: -1s
						}

						#loader-${this.id}.cs-loader :nth-child(3){
							background: #5D4D70;
							animation-delay: -0.5s
						}

						#loader-${this.id}.cs-loader :last-child{
							background: #9D9ABF
						}

						@keyframes spin{
							0%,100%{transform:translate(0)}
							25%{transform:translate(160%)}
							50%{transform:translate(160%, 160%)}
							75%{transform:translate(0, 160%)}
						}
					</style>
				`
			);
		}
	}

	private removeLoader() {
		this.loader.remove();
	}

	private serialize(): ObjectRecord {
		if (!this.form)
			return {};

		const inputs = this.form.find("input").toArray();
		const textareas = this.form.find("textarea").toArray();

		return [...inputs, ...textareas].reduce((result, input) => {
			const current = $(input);
			const key = current.data("cs");
			const value = current.val();

			if(key) {
				result[key] = value;
			}

			return result;
		}, {});
	}

	public startSign({
		url,
		clientKey,
		templateKey,
		snils,
		documentParams,
		onStart = () => {},
		onSignSuccess = () => {},
		onError = () => {},
		onSignError = () => {},
		onCancel = () => {}
	}: WidgetOptions): void {
		const isSafari = /safari/ig.test(`${parser()?.browser?.name}`);
		const rjsfData = this.serialize();
		const wrapper = $(
			"<div>",
			{
				id: `${this.id}-wrapper`,
				css: {
					"display": "flex",
					"justifyContent": "center",
					"alignItems": "center",
					"cursor": "pointer",
					"position": "fixed",
					"top": "0",
					"left": "0",
					"width": "100%",
					"height": "100%",
					"backgroundColor": isSafari ? "#0000004D" : "",
					"backdropFilter": !isSafari ? "blur(2px)" : "",
					"zIndex": "9999"
				}
			}
		);

		this.wrapper = wrapper;

		this.root.css({ overflow: "hidden" });
		this.root.append(wrapper);
		this.appendLoader(wrapper);

    console.log(['snils', snils, documentParams]);

    snils = (snils||documentParams.snils||'').replace(/\D/g,'');

    if (snils.length < 11) {
      alert("Неправильный СНИЛС: " + snils);
      return;
    }

		// TODO: Use right Event type
		const actionHandler = (event: any) => {
			const action = event?.originalEvent?.data?.action ?? "";

			console.info("message", event?.originalEvent?.data);

			switch(action) {
				case "close":
					this.destroy();

					$(window).off("message", actionHandler);

					return;
				case "cancel":
					onCancel();

					$(window).off("message", actionHandler);

					this.destroy();

					return;
				case "result":
					const data = event?.originalEvent?.data?.data ?? {};

					onSignSuccess(data);

					return;
				case "error":
					const error = event?.originalEvent?.data?.error ?? {};

					onSignError(error);

					return;
				default:
					console.info("No action", { action });
			}
		}

		$.ajax({
			method: "POST",
			url: url ?? this.url,
			contentType: "application/json",
			xhrFields: {
				withCredentials: true
			},
			data: JSON.stringify({
				document: {
					client_key: clientKey ?? "",
					template_key: templateKey ?? "",
					snils: snils ?? "",
					document_params: {
						rjsf_data: rjsfData,
						...documentParams,
					}
				}
			}),
		})
			.done((result: any) => {
				const src = result?.url ?? "";
				const session = result?.session;

				$(window).on("message", actionHandler);

				if (src) {
					wrapper.on("click", () => {
						this.destroy();
					});

					const widget = $(
						"<iframe>",
						{
							src: session ? `${src}?key=${session?.key}&value=${session?.value}` : src,
							id: `${this.id}-frame`,
							width: "100%",
							height: "100%",
							frameBorder: "none",
							"frame-border": "none",
							css: {
								border: "0px solid"
							},
						}
					);

					if(session) {
						Cookies.set(session?.key, session?.value, { path: "/", domain: "cloudsigns.ru" });

						this.handleSendMessage({ action: "set-cookie", data: session });
					}

					wrapper.append(widget);

					this.widget = widget;

					this.removeLoader();

					onStart(result);
				};
			})
			.fail((error) => {
				onError(error);

				this.root.css({ overflow: "initial" });

				this.destroy();
			});
	}
}

export default CloudSignsWidget;
